#root {
  height: 100vh;
}
.header-notification {
  display: flex;
  align-items: center;
  max-width: 500px;
  width: 100%;
}
.header-notification > * {
  margin-right: 20px;
}
.header-notification > .notification-bell {
  font-size: 20px;
  cursor: pointer;
}
.header-notification .ant-menu {
  max-width: 500px;
  width: 100%;
  justify-content: end;
}
.header-notification .ant-menu-item {
  height: 70px;
  display: flex;
  align-items: center;
}
.header-notification .ant-menu-submenu {
  height: 70px;
  display: flex;
  align-items: center;
}
.gx-sidebar-content > .ant-menu > .ant-menu-item {
  margin: 12px 0;
}
.gx-sidebar-content > .ant-menu > .ant-menu-item > .menu-icons {
  font-size: 20px;
}
.gx-sidebar-content > .ant-menu > .ant-menu-item > span {
  font-size: 18px;
}
.ant-table .ant-table-tbody .anticon {
  font-size: 20px;
  padding: 0 4px;
}
.ant-table .ant-table-tbody .ant-btn {
  margin: 0;
  padding: 0;
  height: auto;
}
.event-header-image .ant-upload-select-picture-card {
  width: 100%;
}
.event-header-image.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}
.event-form .ant-form-item-label {
  padding: 0;
  margin: 0;
}
.ant-message-custom-content {
  display: flex;
  align-items: center;
}
.ant-message-custom-content > .ant-message-custom-content .ant-message-info {
  height: 36px;
  display: flex;
  align-items: center;
}
.icon-btn {
  display: flex;
  align-items: center;
}
.upload-image-container .ant-upload-select-picture-card {
  width: 100%;
}
.upload-image-container.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}
.custom-icons-list {
  margin-right: 10px;
}
.ant-table-column-sorters {
  padding: 0;
}
.login-content {
  padding: 35px;
  width: 100%;
}
.ant-input-prefix {
  display: flex;
  align-items: center;
  justify-content: center;
}
.reset-password {
  text-align: center;
}
.ant-btn-sm {
  line-height: 0;
}
.attendee-details .ant-form-item {
  min-width: 215px;
  margin-right: 16px;
}
.rsvp-modal .ant-modal-footer div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ant-input-suffix {
  display: flex;
  align-items: center;
}
.option-input {
  border: unset;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0;
}
.option-input:focus {
  border: unset;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0;
  box-shadow: unset;
}
.option-input:hover {
  border-color: transparent;
  border-right-width: 0;
  border: unset;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0;
}
.option-input.ant-input-affix-wrapper-focused {
  box-shadow: unset;
}
.pointer {
  cursor: pointer;
}
.donation-list {
  overflow: auto;
}
.donation-card {
  border: 1px solid #e5e5e5;
  padding: 14px 32px;
  border-radius: 4px;
  margin-right: 24px;
  cursor: pointer;
  min-width: 200px;
  text-align: center;
  min-height: 100px;
  margin-bottom: 12px;
}
.donation-card.active {
  background-color: #efefef;
}
.ministry-modal {
  height: 500px;
}
.ministry-modal .ant-modal-body {
  padding: 0;
}
.ministry-modal object {
  min-height: 500px;
}
.dialog .ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dialog .ant-modal-footer > button:first-child {
  margin-right: 0px;
}
.disable-icon-switch .ant-table .ant-table-tbody .anticon {
  font-size: 14px;
}
.action-icons2 {
  display: flex;
  align-items: center;
}
.action-icons2.ant-table.ant-table-tbody .anticon {
  font-size: 14px;
}
.action-icons2 button:first-child {
  margin-right: 12px;
}
.action-icons2 > button {
  margin-bottom: 0;
}
.m-0 {
  margin: 0;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 40px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-20p {
  margin-top: 20% !important;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-12 {
  margin-right: 12px;
}
.ml-75 {
  margin-left: 75px;
}
.mr-75 {
  margin-right: 75px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-10 {
  margin-left: 10px;
}
.p-0 {
  padding: 0;
}
.gap-8 {
  column-gap: 8px;
}
.d-flex {
  display: flex;
}
.flex-horizontal {
  flex-direction: row;
}
.flex-vertical {
  flex-direction: column;
}
.justify-center {
  justify-content: center;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.flex-grow-1 {
  flex-grow: 1;
}
.flex-grow-2 {
  flex-grow: 2;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.flex-shrink-1 {
  flex-shrink: 1;
}
.flex-shrink-2 {
  flex-shrink: 2;
}
.flex-wrap {
  flex-wrap: wrap;
}
.align-self-end {
  align-self: flex-end;
}
.width-percent-20 {
  width: 20%;
}
.width-percent-50 {
  width: 50%;
}
.width-percent-60 {
  width: 60%;
}
.width-percent-95 {
  width: 95%;
}
.width-percent-40 {
  width: 40%;
}
.width-percent-5 {
  width: 5%;
}
.width-percent-25 {
  width: 25%;
}
.fill-width {
  width: 100%;
}
.maxCol-width {
  width: 150px;
}
.grid {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
.pointer {
  cursor: pointer;
}
.b-0 {
  border: none;
}
.otp-div > :first-child {
  margin: 5px !important;
  padding: 10px !important;
}
.otp-div {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.otp-div input {
  border-radius: 5px;
  border-color: #030846;
  padding: 10px !important;
}
.site-card-border-less-wrapper {
  padding: 30px;
  background: #ececec;
}
.btnsave {
  float: right;
  margin: 5px;
}
.site-card-wrapper-workspace {
  padding: 15px;
  display: inline-block;
  margin-left: 15px;
}
.cardworkspace {
  width: 250px;
  text-align: center;
}
.center-element {
  text-align: center;
}
.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.profile-image-70 {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.profile-image-40 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.avatar-uploader > .ant-upload {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 0px 0 0px 24px;
  width: 1000px;
}
.site-layout-background {
  background: #fff;
}
.inline-display {
  display: flex;
  align-items: center;
  place-items: inherit;
}
.fontsize-18 {
  font-size: 18px !important;
}
.fontsize-20 {
  font-size: 20px !important;
}
.fontsize-22 {
  font-size: 22px !important;
}
.fontsize-28 {
  font-size: 28px !important;
}
.fontsize-50 {
  font-size: 50px !important;
}
.justify-text {
  text-align: justify;
  outline: '30px' !important;
}
.fontWeight-600 {
  font-weight: 600 !important;
}
.custom-file-upload {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 6px;
  margin-bottom: 25px;
}
.pointer {
  cursor: pointer;
}
.logo {
  font-size: 32px;
  font-family: 'Allan', cursive;
  margin-left: 6px;
}
.folder-dropdown {
  opacity: 0;
}
.sidebar-menu.ant-menu .ant-menu-item {
  margin: 8px 0px;
}
.sidebar-menu.ant-menu .ant-menu-item:hover {
  background-color: #fff4f0;
}
.sidebar-menu.ant-menu .ant-menu-item:hover .folder-dropdown {
  opacity: 1;
}
.folder-dropdown {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.folder-icon {
  margin-right: 0px !important;
}
.logo-icon {
  width: 120px;
  height: auto;
}
.main-title {
  color: #303179;
  font-weight: 600;
}
.sub-title {
  font-weight: 600;
  font-size: 16px;
}
.link-icon {
  color: #303179;
}
.workspace-name {
  color: #303179;
}
.tab-menu {
  height: calc(100vh - 215px);
  overflow: auto;
}
.tab-menu .ant-tabs-nav {
  background: #ffffff;
  z-index: 1;
}
.tab-menu .ant-tabs-content-holder {
  overflow: auto;
}
.create-link {
  font-weight: 600;
  font-size: 18px;
}
.disable-create-link {
  cursor: not-allowed;
  text-decoration: line-through;
  color: #8c8c8c;
}
.ant-table {
  border: 1px solid #eae7dc;
}
.ant-table-tbody > tr > td {
  border-bottom: none;
}
.new-folder {
  padding: 0 16px;
  margin: 12px 0px;
}
.separator {
  margin: 0px 5px;
}
.add-workspace {
  font-size: 16px;
}
.nav-row {
  background-color: white;
  padding: 10px 0px;
}
.ant-btn-sm {
  height: 30px;
}
.ant-modal-footer .ant-btn {
  border-radius: 6px;
}
.auth-text {
  margin-bottom: 0px;
  line-height: 24px;
}
.btn_link {
  color: #303179;
}
.action-icons svg {
  width: 20px;
  height: 20px;
}
.logo_text {
  color: #303179;
}
.logo_text_2 {
  color: #ec6f5b;
}
.red_col {
  color: red;
}
.orange_col {
  color: #ef8b7a;
}
.ant-select-selection-placeholder {
  text-align: left;
}
.ant-select-selection-item {
  flex: none;
}
.site-card-wrapper-analytics {
  padding: 15px;
  display: inline-block;
}
.line-through {
  text-decoration-line: line-through;
}
.ant-form-item-explain-error {
  margin-right: auto;
}
.ant-select-selection-placeholder {
  right: 0px !important;
}
.d-none {
  display: none !important;
}
.ant-layout-sider {
  overflow: auto !important;
}
.ant-layout .ant-layout-sider {
  background-color: #fff;
}
.align-justify {
  text-align: justify !important;
}
.mt-8p {
  margin-top: 20%;
}
.height-200 {
  height: 200px;
}
.gx-main-content-wrapper {
  margin-bottom: 20px;
}
.anticon {
  padding: 0 0px !important;
}
@media (min-width: 992px) {
  .hamburger {
    display: none;
  }
}
@media (max-width: 992px) {
  .login-card {
    width: 50% !important;
  }
  .header-menu {
    display: none;
  }
  .nav-row {
    padding: 0px 0px;
  }
  .ant-drawer-right .ant-drawer-content-wrapper {
    padding: 0px;
    width: 245px !important;
  }
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: 0px solid #ffffff !important;
  }
  .center-logo {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .member-table {
    padding: 16px 5px !important;
  }
}
.pl-50 {
  padding-left: 50px !important;
}
.img-upload {
  position: relative;
}
.img-upload:hover .upload-text {
  display: block;
}
.upload-text {
  position: absolute;
  z-index: 100;
  width: 100%;
  top: 45%;
  display: none;
  color: #303179;
  font-weight: bold;
}
.fixed-header {
  z-index: 1000;
  width: 100%;
}
.workspace-list {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  margin: -32px -32px 0;
}
@media screen and (max-width: 768px) {
  .workspace-list {
    margin: 0px;
  }
}
.folder-list {
  height: calc(100vh - 140px);
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .folder-list {
    height: calc(100vh - 200px);
  }
}
.workspace-data {
  max-height: calc(100vh - 500px);
  overflow-x: hidden;
}
.ant-layout-sider-children {
  border-right: 2px solid #f2e4e4;
}
.infinite-list {
  overflow: auto;
}
.custom-filter-dropdown {
  padding: 12px;
  width: 254px;
  word-wrap: break-word;
}
.custom-filter-dropdown .range-input {
  margin-top: 10px;
}
.custom-filter-dropdown .filter-search {
  width: 100%;
  margin-bottom: 12px;
}
.custom-filter-dropdown .divider-filter {
  margin: 10px 0px;
}
.custom-filter-dropdown .ok-button {
  width: 69px;
}
.custom-filter-dropdown .reset-button {
  width: 69px;
  margin-right: 9px;
  border-radius: 6px;
  color: #ec6f5b;
  background-color: #f1f3f7;
  border: none;
}
.custom-filter-dropdown .reset-button.ant-btn[disabled],
.custom-filter-dropdown .ant-btn[disabled]:hover,
.custom-filter-dropdown .ant-btn[disabled]:focus,
.custom-filter-dropdown .ant-btn[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
}
.custom-filter-dropdown .filter-checkboxes {
  margin-top: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}
.custom-filter-dropdown .filter-checkboxes .single-checkbox-div {
  margin-bottom: 9px;
}
.custom-filter-dropdown .filter-checkboxes .single-checkbox-div .single-checkbox .ant-checkbox-inner {
  border: none;
  border-radius: 5px;
  background-color: #e9ebf2;
}
.custom-filter-dropdown .filter-checkboxes .single-checkbox-div .single-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ec6f5b;
}
.custom-filter-dropdown .filter-checkboxes .ant-checkbox-wrapper {
  font-size: 13px;
}
.custom-filter-dropdown .selected-divider {
  font-size: 13px;
  margin: 0px;
}
.custom-filter-dropdown .filter-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.custom-filter-dropdown .filter-section .filter-tag {
  margin: 4px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}
.custom-filter-dropdown .filter-section .filter-tag span:first-child {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.custom-filter-dropdown ::-webkit-scrollbar {
  width: 8px;
  /* width of the entire scrollbar */
}
.custom-filter-dropdown ::-webkit-scrollbar-track {
  background: #fde6e2;
  /* color of the tracking area */
  border-radius: 20px;
}
.custom-filter-dropdown ::-webkit-scrollbar-thumb {
  background-color: #ec6f5b;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
}
.max-width-column {
  max-width: 400px;
}
.profile-popover .ant-menu-inline,
.profile-popover .ant-menu-vertical,
.profile-popover .ant-menu-vertical-left {
  border-right: unset;
}
/*Login Styles*/
.gx-login-container {
  position: relative;
  height: 100%;
  padding-bottom: 30px;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.gx-login-content {
  max-width: 420px;
  width: 94%;
  margin: auto;
  padding: 35px 35px 20px;
  background-color: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  font-size: 14px;
}
.gx-login-content .ant-input {
  background-color: #f5f5f5;
  height: auto;
  padding: 6px 12px;
}
.gx-login-content .ant-input:focus {
  box-shadow: none;
  border-color: #ec6f5b;
}
.gx-login-content .ant-input-affix-wrapper {
  background-color: #f5f5f5;
  max-height: none;
}
.gx-login-content .ant-form-item-control-wrapper {
  width: 100%;
}
.gx-login-content .ant-form-item-children {
  display: block;
}
@media screen and (max-width: 575px) {
  .gx-login-content {
    padding: 20px 20px 10px;
  }
}
.gx-login-header {
  margin-bottom: 30px;
}
@media screen and (max-width: 575px) {
  .gx-login-header {
    margin-bottom: 15px;
  }
}
.gx-app-login-wrap {
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  overflow-x: hidden;
}
@media screen and (max-width: 575px) {
  .gx-app-login-wrap {
    padding-top: 20px;
    -webkit-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
  }
}
.gx-app-login-container {
  position: relative;
  max-width: 680px;
  width: 94%;
  margin: 0 auto;
}
@media screen and (max-width: 575px) {
  .gx-app-login-container {
    padding-bottom: 20px;
  }
}
.gx-app-login-container .gx-loader-view {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 0;
  bottom: 0;
  z-index: 2;
}
.gx-app-login-main-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #fff;
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  font-size: 14px;
  overflow: hidden;
}
.gx-app-login-content {
  padding: 35px 35px 20px;
  width: 60%;
}
.gx-app-login-content .ant-input {
  background-color: #f5f5f5;
}
.gx-app-login-content .ant-input:focus {
  box-shadow: none;
  border-color: #ec6f5b;
}
.gx-app-login-content .gx-btn {
  padding: 6px 35px !important;
  height: auto;
}
.gx-app-login-content .ant-form-item-control-wrapper {
  width: 100%;
}
.gx-app-login-content .ant-form-item-children {
  display: block;
}
@media screen and (max-width: 575px) {
  .gx-app-login-content {
    width: 100%;
    padding: 20px 20px 10px;
  }
}
.gx-app-login-header {
  margin-bottom: 30px;
}
@media screen and (max-width: 575px) {
  .gx-app-login-header {
    margin-bottom: 15px;
  }
}
.gx-app-logo-content {
  color: #fff;
  padding: 35px 35px 20px;
  width: 40%;
  position: relative;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.gx-app-logo-content > * {
  position: relative;
  z-index: 2;
}
.gx-app-logo-content h1 {
  color: #fff;
}
@media screen and (max-width: 575px) {
  .gx-app-logo-content {
    width: 100%;
    padding: 20px 20px 10px;
  }
}
.gx-app-logo-content-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.gx-app-logo-content-bg:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  right: 0;
  bottom: 0;
  background-color: rgba(236, 111, 91, 0.7);
}
.gx-app-logo-content-bg img {
  width: 100%;
  height: 100%;
}
.gx-app-logo-wid {
  margin-bottom: auto;
}
.gx-app-social-block {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
}
.gx-app-social-block .gx-social-link,
.gx-app-social-block .gx-social-link li {
  margin: 0;
}
.gx-app-social-block .gx-social-link span {
  border: solid 1px #ec6f5b;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #ec6f5b;
}
.gx-app-social-block .gx-social-link span:hover,
.gx-app-social-block .gx-social-link span:focus {
  color: #fff;
  background-color: #ec6f5b;
}
.gx-app-social-block p {
  margin-bottom: 0;
}
.gx-signin-form .ant-form-item-control-input {
  min-height: 40px;
}
/* Ant Layout Style */
.ant-layout {
  position: relative;
  background: none;
}
.ant-layout > .gx-layout-content {
  overflow-x: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  background-color: #f5f5f5;
}
.full-scroll .ant-layout > .gx-layout-content {
  overflow-x: inherit;
}
.ant-layout.ant-layout-has-sider > .ant-layout {
  position: relative;
  background: #f5f5f5;
}
.framed-layout .ant-layout,
.boxed-layout .ant-layout {
  background: #f5f5f5;
}
.framed-layout .ant-layout,
.boxed-layout .ant-layout {
  background: #f5f5f5;
}
.ant-layout.gx-app-layout {
  height: 100vh;
}
.framed-layout .ant-layout.gx-app-layout {
  height: calc(100vh - 2 * 20px);
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-layout.gx-app-layout {
    height: 100vh;
  }
}
.framed-layout.full-scroll .ant-layout.gx-app-layout,
.boxed-layout.full-scroll .ant-layout.gx-app-layout {
  overflow-y: scroll;
}
.ant-layout.gx-app-layout > .ant-layout {
  height: 100vh;
}
.framed-layout .ant-layout.gx-app-layout > .ant-layout {
  height: calc(100vh - 2 * 20px);
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-layout.gx-app-layout > .ant-layout {
    height: 100vh;
  }
}
.full-scroll .ant-layout.ant-layout-has-sider > .ant-layout,
.full-scroll .ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: inherit;
}
.gx-main-content-wrapper {
  padding: 32px 32px 0;
  flex: 1;
}
@media screen and (max-width: 575px) {
  .gx-main-content-wrapper {
    padding: 20px 15px 0;
  }
}
.gx-container-wrap .gx-main-content-wrapper {
  width: 94vw;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (min-width: 768px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 720px;
  }
}
@media screen and (min-width: 992px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 920px;
  }
}
@media screen and (min-width: 1200px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 1100px;
  }
}
@media screen and (min-width: 1367px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 1200px;
  }
}
@media screen and (min-width: 1600px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 1400px;
  }
}
.gx-main-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  flex: 1;
  height: 100%;
}
.gx-container {
  width: 94vw;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (min-width: 768px) {
  .gx-container {
    width: 720px;
  }
}
@media screen and (min-width: 992px) {
  .gx-container {
    width: 920px;
  }
}
@media screen and (min-width: 1200px) {
  .gx-container {
    width: 1100px;
  }
}
@media screen and (min-width: 1367px) {
  .gx-container {
    width: 1200px;
  }
}
@media screen and (min-width: 1600px) {
  .gx-container {
    width: 1400px;
  }
}
/* Header Style */
.ant-layout-header {
  border-bottom: 1px solid #eae7dc;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  line-height: 1;
  padding: 0 32px;
  height: 72px;
  position: relative;
  z-index: 10;
  color: #262626;
  background-color: #fff;
}
@media screen and (max-width: 991px) {
  .ant-layout-header {
    padding: 0 15px;
  }
}
@media screen and (max-width: 575px) {
  .ant-layout-header {
    height: 50px;
  }
}
.gx-nav-header {
  padding: 0 32px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  background-color: #fefefe;
}
.gx-nav-header .ant-menu-horizontal {
  margin-bottom: -1px;
  border-bottom-color: transparent;
}
.gx-nav-header-below {
  -webkit-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.28);
  position: relative;
  z-index: 999;
}
.ant-dropdown-menu-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.gx-linebar {
  font-size: 20px;
}
.gx-header-horizontal-dark .gx-linebar {
  color: #fff;
}
.gx-header-notifications {
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.gx-header-notifications > li {
  font-size: 18px;
}
.gx-header-notifications > li:not(:last-child) {
  margin-right: 42px;
}
.gx-inside-header-horizontal .gx-header-notifications > li:not(:last-child) {
  margin-right: 20px;
}
.gx-header-horizontal-main .gx-header-notifications > li:not(:last-child),
.gx-header-horizontal-nav .gx-header-notifications > li:not(:last-child) {
  margin-right: 30px;
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-main .gx-header-notifications > li:not(:last-child),
  .gx-header-horizontal-nav .gx-header-notifications > li:not(:last-child) {
    margin-right: 16px;
  }
}
@media screen and (max-width: 991px) {
  .gx-header-notifications > li:not(:last-child) {
    margin-right: 16px;
  }
}
.gx-header-notifications > li.gx-notify {
  margin-right: 22px;
}
.gx-header-horizontal-main .gx-header-notifications > li.gx-notify {
  margin-right: 30px;
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-main .gx-header-notifications > li.gx-notify {
    margin-right: 16px;
  }
}
.gx-inside-header-horizontal .gx-header-notifications > li.gx-notify {
  margin-right: 20px;
}
@media screen and (max-width: 991px) {
  .gx-header-notifications > li.gx-notify {
    margin-right: 16px;
  }
}
.gx-header-notifications > li.gx-language {
  font-size: 14px;
}
@media screen and (max-width: 575px) {
  .gx-header-notifications > li.gx-language .gx-language-name,
  .gx-header-notifications > li.gx-language .icon {
    display: none;
  }
  .gx-header-notifications > li.gx-language .flag.flag-24 {
    width: 20px;
    height: 20px;
    margin-top: -4px;
  }
}
@media screen and (max-width: 575px) {
  .gx-header-notifications > li.gx-notify,
  .gx-header-notifications > li.gx-msg {
    margin-top: 2px;
  }
  .gx-header-notifications > li.gx-language {
    margin-bottom: 2px;
  }
}
.gx-header-horizontal-main .gx-header-notifications > li.gx-user-nav {
  margin-left: 50px;
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-main .gx-header-notifications > li.gx-user-nav {
    margin-left: 0;
  }
}
@media screen and (max-width: 1199px) {
  .gx-inside-header-horizontal .gx-header-horizontal-main .gx-header-notifications > li.gx-user-nav {
    margin-left: 0;
  }
}
.gx-header-horizontal-main .gx-header-notifications li > span {
  color: #545454;
}
.gx-header-horizontal-dark .gx-header-horizontal-main .gx-header-notifications li > span {
  color: #eae7dc;
}
.gx-popover-scroll {
  height: 280px !important;
  width: 300px !important;
  margin: 0 -16px;
}
@media screen and (max-width: 575px) {
  .gx-popover-scroll {
    height: 280px !important;
    width: 200px !important;
  }
}
.gx-popover-lang-scroll {
  height: 220px !important;
  width: 160px !important;
  margin: 0 -16px;
}
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  right: 10px;
}
.gx-popover-header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  border-bottom: solid 1px #e8e8e8;
  margin: 0 -16px;
  padding: 0 16px 12px;
}
.gx-sub-popover {
  list-style: none;
  margin: 0 16px;
  padding-left: 0;
}
.gx-sub-popover li {
  padding: 20px 0 14px;
}
.gx-popover-lang-scroll .gx-sub-popover li {
  padding: 5px 0;
}
.gx-sub-popover li:not(:last-child) {
  border-bottom: solid 1px #e8e8e8;
}
.gx-user-popover {
  margin: -12px -16px;
  padding: 7px 0;
  list-style: none;
}
.gx-user-popover li {
  cursor: pointer;
  padding: 3px 15px;
  width: 150px;
}
.gx-user-popover li:hover,
.gx-user-popover li:focus {
  background-color: #fafafa;
}
@media screen and (max-width: 575px) {
  .gx-user-popover li {
    width: 100%;
  }
}
.gx-header-horizontal {
  position: relative;
  color: #eae7dc;
  background-color: #fff;
}
.gx-header-horizontal-dark {
  position: relative;
  background-color: #003366;
  color: #eae7dc;
}
.gx-header-horizontal-dark:before {
  content: '';
  position: absolute;
  right: 169px;
  top: -212px;
  background-color: rgba(255, 255, 255, 0.2);
  width: 280px;
  height: 350px;
  -webkit-border-radius: 0 0 135px 135px;
  -moz-border-radius: 0 0 135px 135px;
  border-radius: 0 0 135px 135px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 1;
}
@media screen and (max-width: 1049px) {
  .gx-header-horizontal-dark:before {
    right: 110px;
  }
}
@media screen and (max-width: 1199px) {
  .gx-header-horizontal-dark:before {
    top: -184px;
    right: 80px;
    height: 300px;
  }
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-dark:before {
    top: -154px;
    right: 140px;
    height: 240px;
    width: 200px;
  }
}
@media screen and (max-width: 575px) {
  .gx-header-horizontal-dark:before {
    right: 60px;
    display: none;
  }
}
.gx-header-horizontal-dark > div {
  position: relative;
  z-index: 2;
}
.gx-header-horizontal-top {
  padding: 12px 0;
  font-size: 14px;
}
.gx-header-horizontal-top-flex,
.gx-header-horizontal-nav-flex,
.gx-header-horizontal-main-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.gx-header-horizontal-top-left {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-right: 15px;
}
@media screen and (max-width: 767px) {
  .gx-header-horizontal-top-left {
    margin-right: 0;
    width: 100%;
  }
}
.gx-header-horizontal-main {
  display: -webkit-block;
  display: -ms-blockbox;
  display: -ms-block;
  display: block;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 25px 0 35px;
  height: auto;
  background-color: transparent;
}
@media screen and (max-width: 1199px) {
  .gx-header-horizontal-main {
    padding: 20px 0;
  }
  .gx-header-horizontal-main .gx-w-logo {
    padding: 7px 5px;
  }
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-main {
    padding: 0;
  }
}
@media screen and (max-width: 575px) {
  .gx-header-horizontal-main .gx-w-logo {
    padding-right: 0;
  }
}
.gx-inside-header-horizontal .gx-header-horizontal-main {
  padding: 0;
}
.gx-header-horizontal-nav {
  background-color: #003366;
  color: #fffffd;
}
.gx-header-horizontal-dark .gx-header-horizontal-nav {
  background-color: transparent;
}
.gx-below-header-horizontal .gx-header-horizontal-nav,
.gx-above-header-horizontal .gx-header-horizontal-nav {
  background-color: #fefefe;
  color: #7a7a7a;
}
.gx-inside-header-horizontal .gx-logo {
  margin-right: 25px;
}
.gx-inside-header-horizontal:before {
  top: -230px;
}
@media screen and (max-width: 1199px) {
  .gx-inside-header-horizontal:before {
    top: -190px;
  }
}
@media screen and (max-width: 991px) {
  .gx-inside-header-horizontal:before {
    top: -154px;
  }
}
.gx-above-header-horizontal:before {
  top: -165px;
}
@media screen and (max-width: 991px) {
  .gx-above-header-horizontal:before {
    top: -154px;
  }
}
.gx-login-list {
  list-style: none;
  margin-left: auto;
  margin-bottom: 0;
  padding-left: 0;
}
.gx-login-list li {
  padding: 0 10px;
  text-transform: uppercase;
  color: #eae7dc;
  font-size: 12px;
  line-height: 12px;
  display: inline-block;
  cursor: pointer;
}
.gx-login-list li:not(:first-child) {
  border-left: solid 1px #e8e8e8;
}
.gx-header-horizontal-dark .gx-login-list li:not(:first-child) {
  border: 0 none;
}
@media screen and (max-width: 767px) {
  .gx-login-list {
    display: none;
  }
}
.gx-header-search {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-left: 28px;
}
.gx-header-search .ant-select-selection,
.gx-header-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  background-color: #003366;
  border-color: #003366;
  color: #fffffd;
  margin-bottom: 0;
}
.gx-header-search .ant-select-selection:focus,
.gx-header-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:focus {
  background-color: #003366;
  border-color: #003366;
  color: #fffffd;
}
.gx-header-horizontal-dark .gx-header-search .ant-select-selection,
.gx-header-horizontal-dark .gx-header-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  color: #fffffd;
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
  margin-left: 1px;
}
.gx-header-horizontal-dark .gx-header-search .ant-select-selection:focus,
.gx-header-horizontal-dark .gx-header-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:focus {
  color: #fffffd;
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}
.gx-header-search .ant-select-arrow {
  color: #fffffd;
}
.gx-popover-horizantal .ant-btn {
  margin: 0 !important;
  border: 0 none;
  border-right: solid 1px #e8e8e8;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  height: 24px;
  line-height: 24px;
}
.gx-popover-horizantal .gx-popover-search-bar {
  margin: 0;
}
.gx-popover-horizantal .gx-popover-search-bar .ant-input {
  height: 24px;
  line-height: 24px;
}
@media screen and (max-width: 575px) {
  .gx-popover-horizantal {
    left: 0 !important;
    padding-top: 0;
    margin-top: -9px;
  }
  .gx-popover-horizantal .ant-popover-inner-content {
    width: 100vw;
  }
  .gx-popover-horizantal .ant-popover-arrow {
    display: none;
  }
  .gx-popover-horizantal .gx-popover-search-bar {
    width: 100%;
  }
  .gx-popover-horizantal .gx-popover-scroll,
  .gx-popover-horizantal .gx-popover-lang-scroll {
    width: 100% !important;
    margin: 0;
  }
  .gx-popover-horizantal .gx-sub-popover {
    margin: 0;
    padding-left: 5px;
  }
}
.gx-no-header-horizontal {
  padding: 14px 32px 13px 79px;
  text-align: center;
  position: relative;
}
.gx-no-header-horizontal-top {
  padding: 10px 20px;
  background-color: #d9f7be;
  color: #237804;
  border-radius: 4px;
  display: inline-block;
}
@media screen and (max-width: 575px) {
  .gx-no-header-horizontal-top {
    width: 100%;
    font-size: 12px;
  }
}
.gx-no-header-horizontal-top-center {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.gx-no-header-horizontal .gx-linebar {
  position: absolute;
  left: 32px;
  top: 15px;
  z-index: 1;
}
@media screen and (max-width: 575px) {
  .gx-no-header-horizontal .gx-linebar {
    left: 15px;
  }
}
@media screen and (max-width: 575px) {
  .gx-no-header-horizontal {
    padding: 14px 15px 13px 60px;
  }
}
/* Sidebar Style */
.gx-app-sidebar {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.ant-layout-sider {
  background-color: #fffffc;
  color: #545454;
  flex: 0 0 auto !important;
  max-width: none !important;
  min-width: 10px !important;
  width: 260px !important;
  overflow: hidden;
}
@media screen and (max-width: 1366px) {
  .ant-layout-sider {
    width: 220px !important;
  }
}
.ant-layout-sider-collapsed {
  width: 80px !important;
}
@media screen and (max-width: 991px) {
  .ant-layout-sider-collapsed.gx-mini-custom-sidebar {
    width: 0 !important;
    min-width: 0 !important;
  }
}
.ant-layout-sider.gx-collapsed-sidebar {
  width: 0 !important;
  min-width: 0 !important;
}
@media screen and (max-width: 991px) {
  .ant-layout-sider {
    width: 0 !important;
    min-width: 0 !important;
  }
}
.gx-layout-sider-dark {
  background-color: #003366;
  color: #038fdd;
}
.gx-layout-sider-header {
  padding: 10px 30px 10px 40px;
  height: 72px;
  background-color: #fff;
  border-bottom: 1px solid #eae7dc;
  position: relative;
  z-index: 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
@media screen and (max-width: 575px) {
  .gx-layout-sider-header {
    height: 50px;
  }
}
.gx-layout-sider-header .gx-site-logo {
  display: block;
}
.ant-layout-sider-collapsed .gx-layout-sider-header .gx-site-logo {
  display: none;
}
.gx-mini-custom-sidebar .gx-layout-sider-header .gx-site-logo {
  display: block;
}
.gx-layout-sider-header .gx-linebar {
  position: absolute;
  left: 20px;
  z-index: 1;
  top: 15px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.ant-layout-sider-collapsed .gx-layout-sider-header .gx-linebar {
  left: 20px;
}
@media screen and (max-width: 991px) {
  .gx-layout-sider-header .gx-linebar {
    display: none;
  }
}
.ant-layout-sider-collapsed .gx-layout-sider-header {
  padding-left: 20px;
  padding-right: 20px;
}
.gx-drawer-sidebar .gx-layout-sider-header {
  padding-left: 30px;
}
.gx-drawer-sidebar-dark .gx-layout-sider-header {
  background-color: rgba(0, 0, 0, 0.3);
}
.gx-layout-sider-dark .gx-layout-sider-header {
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.gx-mini-custom-sidebar .gx-layout-sider-header {
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  background-color: transparent;
}
.gx-custom-sidebar .gx-layout-sider-header {
  padding-left: 30px;
  background-color: transparent;
}
.gx-mini-custom-sidebar.ant-layout-sider-lite .gx-layout-sider-header,
.gx-custom-sidebar.ant-layout-sider-lite .gx-layout-sider-header {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-right: 1px solid #e8e8e8;
}
@media screen and (max-width: 991px) {
  .gx-layout-sider-header {
    padding-left: 30px;
  }
}
.gx-sidebar-content {
  border-right: 1px solid #eae7dc;
  height: inherit;
}
.gx-layout-sider-dark .gx-sidebar-content,
.gx-drawer-sidebar-dark .gx-sidebar-content {
  border-right: 0 none;
}
.gx-layout-sider-scrollbar {
  height: calc(100vh - 72px - 145px) !important;
}
.framed-layout .gx-layout-sider-scrollbar {
  height: calc(100vh - 72px - 2 * 20px - 145px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .gx-layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
.ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
  height: calc(100vh - 72px - 91px) !important;
}
.framed-layout .ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
  height: calc(100vh - 72px - 2 * 20px - 91px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
@media screen and (max-width: 991px) {
  .ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
@media screen and (max-width: 575px) {
  .ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
    height: calc(100vh - 50px) !important;
  }
}
.gx-mini-custom-sidebar .gx-layout-sider-scrollbar {
  height: calc(100vh - 72px - 206px) !important;
}
.framed-layout .gx-mini-custom-sidebar .gx-layout-sider-scrollbar {
  height: calc(100vh - 72px - 2 * 20px - 206px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .gx-mini-custom-sidebar .gx-layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
@media screen and (max-width: 991px) {
  .gx-layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-layout-sider-scrollbar {
    height: calc(100vh - 50px) !important;
  }
}
@media screen and (max-width: 991px) {
  .gx-no-header-notifications + .gx-layout-sider-scrollbar {
    height: calc(100vh - 72px - 145px) !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-no-header-notifications + .gx-layout-sider-scrollbar {
    height: calc(100vh - 50px - 145px) !important;
  }
}
.gx-sidebar-notifications {
  padding: 30px 10px 10px;
  margin: 0 20px 10px;
  border-bottom: solid 1px #e8e8e8;
}
.ant-layout-sider-dark .gx-sidebar-notifications,
.gx-drawer-sidebar-dark .gx-sidebar-notifications {
  border-bottom-color: #038fdd;
}
@media screen and (max-width: 991px) {
  .gx-sidebar-notifications {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .gx-sidebar-notifications.gx-no-header-notifications {
    display: block;
  }
}
.gx-app-nav {
  list-style: none;
  padding-left: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin: 0 -20px;
  color: #ec6f5b;
}
.ant-layout-sider-dark .gx-app-nav,
.gx-drawer-sidebar-dark .gx-app-nav {
  color: #038fdd;
}
.gx-app-nav li {
  padding: 0 20px;
  font-size: 20px;
}
.gx-app-nav li .icon {
  cursor: pointer;
}
.ant-layout-sider-collapsed .gx-app-nav {
  display: none;
}
.gx-mini-custom-sidebar .gx-app-nav {
  display: block;
  margin-top: 15px;
}
.gx-mini-custom-sidebar .gx-app-nav li:not(:last-child) {
  margin-bottom: 5px;
}
.gx-avatar-name {
  cursor: pointer;
}
.ant-layout-sider-collapsed .gx-avatar-name {
  display: none;
}
.ant-layout-sider-collapsed .gx-avatar-row {
  margin-bottom: 0 !important;
}
.gx-avatar-row .ant-avatar {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  position: relative;
  left: 0;
}
.ant-layout-sider-collapsed .gx-avatar-row .ant-avatar {
  margin: 0 !important;
  left: -10px;
}
.gx-menu-group .ant-menu-item-group-title {
  font-size: 16px;
  padding-top: 30px !important;
}
.gx-layouts-view .gx-pointer {
  display: block;
  margin-bottom: 15px;
}
.gx-layouts-view .gx-pointer img {
  width: 100%;
}
.gx-layouts-view .gx-pointer.active {
  box-shadow: 0 0 10px 0 #ec6f5b;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
/* Footer Style */
.ant-layout-footer {
  border-top: solid 1px #e8e8e8;
  background-color: #fff;
}
@media screen and (max-width: 575px) {
  .ant-layout-footer {
    padding: 14px 15px;
  }
}
.gx-container-wrap .ant-layout-footer {
  padding-left: 0;
  padding-right: 0;
}
.gx-container-wrap .gx-layout-footer-content {
  width: 94vw;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (min-width: 768px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 720px;
  }
}
@media screen and (min-width: 992px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 920px;
  }
}
@media screen and (min-width: 1200px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 1100px;
  }
}
@media screen and (min-width: 1367px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 1200px;
  }
}
@media screen and (min-width: 1600px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 1400px;
  }
}
/* Customizer Style */
.gx-customizer {
  height: calc(100vh - 30px) !important;
}
.gx-customizer-item:not(:last-child) {
  border-bottom: solid 1px #e8e8e8;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.gx-customizer-item .ant-radio-group .ant-radio-button {
  display: none;
}
.gx-customizer-option {
  position: absolute;
  right: 0;
  top: 150px;
  z-index: 99;
}
.full-scroll .gx-customizer-option {
  position: fixed;
}
.full-scroll.boxed-layout .gx-customizer-option {
  position: absolute;
}
.full-scroll.framed-layout .gx-customizer-option {
  right: 20px;
}
@media screen and (max-width: 991px) {
  .full-scroll.framed-layout .gx-customizer-option {
    right: 0;
  }
}
.horizontal-layout.full-scroll .gx-customizer-option {
  top: 200px;
}
@media screen and (max-width: 575px) {
  .gx-customizer {
    width: 250px !important;
  }
  .gx-customizer .ant-radio-button-wrapper {
    padding: 0 10px;
  }
}
.gx-customizer .gx-list-inline {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.framed-layout .gx-customizer {
  height: calc(100vh - 30px - 2 * 20px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .gx-customizer {
    height: calc(100vh - 30px) !important;
  }
}
.gx-color-option {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}
.gx-color-option li {
  font-size: 36px;
  line-height: 1;
}
.gx-color-option li .gx-link {
  width: 40px;
  height: 40px;
  display: block;
  position: relative;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
}
.gx-color-option li .gx-link:before {
  font-family: 'gaxon';
  font-size: 20px;
  content: '\30';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: none;
}
.gx-color-option li .gx-link.active:before {
  display: block;
}
.gx-color-option li .gx-link:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 20px;
  height: 40px;
  background-color: #9283d4;
}
.gx-color-option li .gx-link.gx-color-red:after {
  background-color: #ff2b7a;
}
.gx-color-option li .gx-link.gx-color-blue:after {
  background-color: #3da4e6;
}
.gx-color-option li .gx-link.gx-color-dark-blue:after {
  background-color: #0469b9;
}
.gx-color-option li .gx-link.gx-color-orange:after {
  background-color: #f18805;
}
.gx-color-option li .gx-link.gx-color-light-blue:after {
  background-color: #6a95ff;
}
.gx-color-option li .gx-link.gx-color-deep-orange:after {
  background-color: #f87060;
}
.gx-color-option li .gx-link.gx-color-light-purple1:after {
  background-color: #a172e7;
}
.gx-color-option li .gx-link.gx-color-light-purple2:after {
  background-color: #956fe7;
}
.gx-cus-customiz {
  padding-right: 20px;
}
.gx-layout-option li,
.gx-nav-option li {
  margin-bottom: 10px;
}
.gx-layout-option li span,
.gx-nav-option li span {
  position: relative;
  display: block;
}
.gx-layout-option li span:before,
.gx-nav-option li span:before {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  font-family: 'gaxon' !important;
  content: '\30';
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: none;
  width: 20px;
  font-size: 12px;
  height: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #ec6f5b;
  text-align: center;
  padding: 2px;
  color: #fff;
}
.gx-layout-option li span.active:before,
.gx-nav-option li span.active:before {
  display: block;
}
/* Drawer Style */
.ant-drawer {
  overflow: hidden;
}
.ant-drawer .ant-drawer-header {
  display: flex;
  justify-content: center;
}
.ant-drawer-body {
  padding: 0;
}
.gx-drawer-sidebar-dark .ant-drawer-content {
  background-color: #003366;
  color: #038fdd;
}
.ant-drawer-content-wrapper {
  width: 260px !important;
}
@media screen and (max-width: 991px) {
  .ant-drawer-content-wrapper {
    width: 240px !important;
  }
}
.framed-layout .ant-drawer-content-wrapper {
  top: 20px;
  bottom: 20px;
  height: calc(100vh - 2 * 20px);
  -webkit-border-radius: 11px 0 0 11px;
  -moz-border-radius: 11px 0 0 11px;
  border-radius: 11px 0 0 11px;
  overflow: hidden;
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-drawer-content-wrapper {
    top: 0;
    bottom: 0;
    height: 100vh;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
  }
}
.ant-drawer-right .ant-drawer-content-wrapper {
  width: 370px !important;
  padding: 15px;
  background: #fff;
}
@media screen and (max-width: 991px) {
  .ant-drawer-right .ant-drawer-content-wrapper {
    width: 300px !important;
  }
}
@media screen and (max-width: 575px) {
  .ant-drawer-right .ant-drawer-content-wrapper {
    width: 280px !important;
  }
}
.framed-layout .ant-drawer-right .ant-drawer-content-wrapper {
  top: 20px;
  bottom: 20px;
  height: calc(100vh - 2 * 20px);
  -webkit-border-radius: 0 11px 11px 0;
  -moz-border-radius: 0 11px 11px 0;
  border-radius: 0 11px 11px 0;
  overflow: hidden;
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-drawer-right .ant-drawer-content-wrapper {
    top: 0;
    bottom: 0;
    height: 100vh;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
  }
}
.framed-layout .ant-drawer-content-wrapper,
.boxed-layout .ant-drawer-content-wrapper {
  position: absolute;
}
.boxed-layout .ant-drawer {
  max-width: 1280px;
  margin: 0 auto;
}
@media screen and (min-width: 1600px) {
  .boxed-layout .ant-drawer {
    max-width: 1500px;
  }
}
.framed-layout .ant-drawer-open.ant-drawer-left,
.framed-layout .ant-drawer-open.ant-drawer-right,
.boxed-layout .ant-drawer-open.ant-drawer-left,
.boxed-layout .ant-drawer-open.ant-drawer-right {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.framed-layout .ant-drawer-open {
  width: calc(100% - 2 * 20px);
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-drawer-open {
    width: 100%;
  }
}
@media screen and (min-width: 1600px) {
  .boxed-layout .ant-drawer.ant-drawer-left,
  .boxed-layout .ant-drawer.ant-drawer-right {
    position: absolute;
  }
}
@media screen and (min-width: 991px) {
  .framed-layout .ant-drawer.ant-drawer-left,
  .framed-layout .ant-drawer.ant-drawer-right {
    position: absolute;
  }
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-drawer.ant-drawer-left,
  .framed-layout .ant-drawer.ant-drawer-right {
    right: 20px;
    left: 20px;
    margin: 0;
  }
}
.ant-drawer-close {
  top: -11px;
}
.ant-drawer-close-x {
  width: 35px;
  height: 35px;
  line-height: 35px;
}
.ant-drawer-mask {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
}
